 
import React, { createContext, useState, useContext, useRef } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { AlertHandlers, Props } from 'props';
import { SendOutlined } from '@material-ui/icons';
import Button from '../../components/Button';
import Select from '../../components/Select';
import Input from '../../components/Input';
import IconButton from '../../components/IconButton';
import CloseButton from '../../components/CloseButton';
import { Container } from './styles';

let CustomAlert: React.FC<AlertHandlers> | undefined;

interface AlertOption {
  label: string;
  shape?: 'outline' | 'solid';
  color?: 'accent' | 'primary';
  onClick?: () => void;
  key?: string;
}

interface AlertContextData {
  title?: string;
  message?: string;
  options?: AlertOption[];
  cancelable?: boolean;
  onClose?: () => void;
  type?: 'buttons' | 'select' | 'input' | 'timeout';
  timeout?: number;
  customElement?: {
    component: React.FC<AlertHandlers>;
    isCustom?: boolean;
    props?: any;
  };
}

type AlertContextProps = {
  show: (props?: AlertContextData) => Promise<any>;
};

const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);

const Alert: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [message, setMessage] = useState<string | undefined>();
  const [type, setType] = useState<'buttons' | 'select' | 'input' | 'timeout'>(
    'buttons'
  );
  const [options, setOptions] = useState<AlertOption[] | undefined>();
  const [cancelable, setCancelable] = useState<boolean | undefined>(true);
  const [isCustom, setIsCustom] = useState<boolean | undefined>(false);
  const [customProps, setCustomProps] = useState<any>({});
  const [showClose, setShowClose] = useState(false);
  const [input, setInput] = useState<string>('');
  const resolver = useRef<any>();

  const setCustomAlert = (component?: React.FC<AlertHandlers>) => {
    CustomAlert = component;
  };

  const [state, setState] = useState<any>();

  const handleOk = (key?: any) => {
    if (resolver.current) resolver.current(key || true);
    setIsOpen(false);
  };

  const handleCancel = () => {
    if (resolver.current) resolver.current(false);
    setIsOpen(false);
  };

  const handleShow = (props?: AlertContextData) => {
    setIsOpen(true);
    setTitle(props?.title);
    setMessage(props?.message);
    setOptions(props?.options);
    setType(props?.type || 'buttons');
    setCustomAlert(props?.customElement?.component);
    setCustomProps(props?.customElement?.props);
    setIsCustom(props?.customElement?.isCustom);
    if (props?.cancelable !== undefined) setCancelable(props.cancelable);

    setShowClose(props?.onClose !== undefined);
    setState(props);

    if (props?.type === 'timeout') {
      setTimeout(() => {
        handleOk();
      }, props.timeout || 3000);
    }

    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  return (
    <AlertContext.Provider value={{ show: handleShow }}>
      {children}
      <Modal
        open={isOpen}
        closeAfterTransition
        onClose={() => cancelable && handleCancel && handleCancel()}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            backgroundColor: 'rgba(0, 0, 0, .68)',
            backdropFilter: 'blur(2px)',
          },
        }}
      >
        <Fade in={isOpen}>
          {CustomAlert && isCustom ? (
            <CustomAlert {...{ handleOk, handleCancel, props: customProps }} />
          ) : (
            <Container>
              {(cancelable || showClose) && (
                <CloseButton
                  id="close"
                  onClick={() => {
                    state?.onClose?.();
                    handleCancel();
                  }}
                />
              )}
              {CustomAlert ? (
                <CustomAlert
                  {...{ handleOk, handleCancel, props: customProps }}
                />
              ) : (
                <>
                  <h2>{title}</h2>
                  <p>{message}</p>
                  <div id="actions">
                    {options ? (
                      type === 'select' ? (
                        <>
                          {options.findIndex((o) => o.key === 'cancel') >=
                            0 && (
                            <Button
                              onClick={() => {
                                const option = options.find(
                                  (o) => o.key === 'cancel'
                                );
                                option?.onClick?.();
                                handleOk('cancel');
                              }}
                              shape="outline"
                              size="small"
                            >
                              Cancelar
                            </Button>
                          )}

                          <Select
                            label="Selecione"
                            value=""
                            placeholder="Selecione uma opção"
                            size="small"
                            id="select-option"
                            onChange={({ target }) => {
                              const option = options.find(
                                (o) => o.key === target.value
                              );
                              option?.onClick?.();
                              handleOk(target.value as string);
                            }}
                            options={options
                              .filter((o) => o.key !== 'cancel')
                              .map((option) => ({
                                value: option.key || '',
                                text: option.label,
                              }))}
                          />
                        </>
                      ) : type === 'input' ? (
                        <>
                          {options.findIndex((o) => o.key === 'cancel') >=
                            0 && (
                            <Button
                              onClick={() => {
                                const option = options.find(
                                  (o) => o.key === 'cancel'
                                );
                                option?.onClick?.();
                                handleOk('cancel');
                              }}
                              shape="outline"
                              size="small"
                            >
                              Cancelar
                            </Button>
                          )}
                          <Input
                            label="Informe o valor"
                            placeholder="Digite aqui..."
                            variant="outlined"
                            size="small"
                            type="text"
                            value={input}
                            onChange={({ target }) =>
                              setInput(target.value as string)
                            }
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  size="small"
                                  type="submit"
                                  color="primary"
                                  aria-label="search"
                                  onClick={() => {
                                    handleOk(input);
                                  }}
                                >
                                  <SendOutlined />
                                </IconButton>
                              ),
                            }}
                          />
                        </>
                      ) : (
                        options.map((option) => (
                          <Button
                            key={option.label}
                            onClick={() => {
                              if (option.onClick) option.onClick();
                              handleOk(option.key);
                            }}
                            size="small"
                            shape={option.shape}
                            color={option.color}
                          >
                            {option.label}
                          </Button>
                        ))
                      )
                    ) : (
                      type !== 'timeout' && (
                        <>
                          <Button
                            onClick={() => handleOk()}
                            size="small"
                            shape="outline"
                            color="primary"
                          >
                            Sim
                          </Button>
                          <Button onClick={handleCancel} size="small">
                            Não
                          </Button>
                        </>
                      )
                    )}
                  </div>
                </>
              )}
            </Container>
          )}
        </Fade>
      </Modal>
    </AlertContext.Provider>
  );
};

export const useAlert: () => AlertContextProps = () => useContext(AlertContext);

export default Alert;
