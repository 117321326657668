import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100000;
  align-items: center;
  justify-content: center;

  .network-error {
    padding: 20px;
    display: flex;
    cursor: default;
    max-width: 300px;
    text-align: center;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primary};
    box-shadow: ${({ theme }) => theme.shadows[0]};
    background-color: ${(props) => props.theme.colors.light};

    svg {
      width: 100px;
      height: 100px;
    }

    h2 {
      font-size: 20px;
      margin: 10px 0 20px 0;
    }
    p {
      color: ${({ theme }) => theme.colors.text};
      &#info-2 {
        padding: 0 10px;
        font-size: 10px;
      }
    }

    a {
      font-size: 12px;
      font-weight: bold;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    p,
    a,
    button {
      margin-top: 10px;
    }
  }
`;
