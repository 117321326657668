import { useState, useRef, useEffect, MutableRefObject } from 'react';

export default function useStateSync<T>(initialValue: T) {
  const [state, updateState] = useState<T>(initialValue);
  const stateRef = useRef<T>(initialValue);
  const resolver = useRef<any>();
  stateRef.current = state;

  useEffect(() => {
    if (resolver.current) resolver.current(state);
  }, [state]);

  const set = (newState: T) =>
    new Promise<void>((resolve) => {
      updateState(newState);
      resolver.current = resolve;
      if (newState === stateRef.current) resolve();
    });

  const res: [MutableRefObject<T>, (newState: T) => Promise<void>] = [
    stateRef,
    set,
  ];

  return res;
}
