import axios from '../axios';

export const getEnabled = () =>
  new Promise<any[]>((resolve, reject) => {
    axios
      .get('/linktype')
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getById = (id: string) =>
  new Promise<{ id: string; value: string }>((resolve, reject) => {
    axios
      .get(`/linktype/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
