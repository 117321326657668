export const getProp: any = (obj: any, _path: string, split: string = '.') => {
  const object = obj;
  const path = _path.split(split);
  if (path.length === 1) return object[path[0]];
  if (path.length === 0) throw new Error();
  else if (object[path[0]])
    return getProp(object[path[0]], path.slice(1).join(split));
  else {
    object[path[0]] = {};
    return getProp(object[path[0]], path.slice(1).join(split));
  }
};

export const addDays = (_date: Date, days: number) => {
  const date = new Date(_date.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const getParams = (query: string) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: any, param: any) => {
          const [key, value] = param.split('=');
           
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};

export const dateStringToDate = (dateString: string) =>
  new Date(`${dateString?.replace('Z', '')}Z`);

export const isEnv = (env: string) => process.env.NODE_ENV === env;

export const isDev = () => !process.env.NODE_ENV || isEnv('development');

export const isProd = () => isEnv('production');
