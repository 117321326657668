import {
  Solicitation,
  SolicitationListItemViewModel,
  SolicitationWithCommitteesListItem,
} from 'entities';
import { GetListInputModel, SolicitationReportInputModel } from 'inputModels';
import axios from '../axios';

export const addSolicitation = (solicitation: any) =>
  new Promise<{ protocol: string }>((resolve, reject) => {
    const bodyFormData = new FormData();
    if (solicitation.attachments) {
      const attachments = [...solicitation.attachments];
       
      delete solicitation.attachments;
      attachments.forEach((file: File) => {
        bodyFormData.append('attachments', file);
      });
    }

    Object.entries(solicitation).forEach(([key, value]) => {
      bodyFormData.append(key, (value as any).toString());
    });

    axios
      .post('/solicitation', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const updateStatus = (
  protocol: string,
  status: number,
  updatedBy?: string,
  conclusionStatusId?: string,
  conclusionStatus?: string
) =>
  new Promise<void>((resolve, reject) => {
    axios
      .put(`/solicitation/${protocol}`, {
        status,
        updatedBy,
        conclusionStatusId,
        conclusionStatus,
      })
      .then(() => resolve())
      .catch(reject);
  });

export const sendVerificationCode = (to: string, type: string) =>
  new Promise<void>((resolve, reject) => {
    axios
      .post('/user/code', {
        to: type === 'sms' ? to.replace(/\D/g, '') : to,
        type,
      })
      .then(() => resolve())
      .catch(reject);
  });

export const validateVerificationCode = (code: string, type: string) =>
  new Promise<{ status: number }>((resolve, reject) => {
    axios
      .post('/user/code/validate', {
        code,
        type,
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const anonymousLogin = (protocol: string, contact: string) =>
  new Promise<{ status: number; token: string; user: any }>(
    (resolve, reject) => {
      axios
        .post('/solicitation/login', {
          protocol,
          contact,
        })
        .then(({ data }) => resolve(data))
        .catch(reject);
    }
  );

export const getByProtocol = (protocol: string) =>
  new Promise<Solicitation>((resolve, reject) => {
    axios
      .get(`/solicitation/${protocol}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getByStatus = ({
  status,
  search,
  order,
  page,
  take,
}: GetListInputModel & { status?: number }) =>
  new Promise<SolicitationListItemViewModel[]>((resolve, reject) => {
    axios
      .get('/solicitation', {
        params: {
          status,
          search,
          order,
          page,
          take,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getAll = ({ search, order, page, take }: GetListInputModel) =>
  new Promise<SolicitationWithCommitteesListItem[]>((resolve, reject) => {
    axios
      .get('/solicitation/list', {
        params: {
          search,
          order,
          page,
          take,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getDashboard = () =>
  new Promise<any>((resolve, reject) => {
    axios
      .get('/solicitation/dashboard')
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getReportDashboard = (start?: Date, end?: Date) =>
  new Promise<any[]>((resolve, reject) => {
    axios
      .get(
        `/solicitation/report/dashboard?startDate=${start?.toISOString()}&endDate=${end?.toISOString()}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const reportDownload = (options: SolicitationReportInputModel) =>
  new Promise<any>((resolve, reject) => {
    axios
      .post('/solicitation/report', options, {
        responseType: 'blob',
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
