import axios from '../axios';

export const Error = (error: Error) =>
  new Promise<void>((resolve) =>
    axios
      .post('/logger/error', {
        message: error?.message,
        stack: error?.stack,
      })
      .finally(resolve)
  );

export const Warning = (warning: string) =>
  new Promise<void>((resolve) =>
    axios
      .post('/logger/warning', {
        message: warning,
      })
      .finally(resolve)
  );

export const Info = (info: string) =>
  new Promise<void>((resolve) =>
    axios
      .post('/logger/info', {
        message: info,
      })
      .finally(resolve)
  );
