 import axios from 'axios';
import { AuthViewModel } from 'entities';

let onError: (error: any) => void;
export const setOnError = (cb: (error: any) => void) => {
  onError = cb;
};

let onRefreshToken: (data: AuthViewModel) => void;
export const setOnRefreshToken = (cb: (data: AuthViewModel) => void) => {
  onRefreshToken = cb;
};

// Multi-tenant config
/*const split = process.env.REACT_APP_API_BASE_URL?.split('//');
const tenant = window.location.hostname.includes('.')
  ? window.location.hostname.split('.')[0]
  : '';
const baseURL = `${split?.[0]}//${
  tenant ? `${tenant === 'app' ? 'api' : tenant}.` : ''
}${split?.[1]}`;*/

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const refreshToken = (error?: any) =>
  new Promise((resolve, reject) => {
    try {
      const storageToken = localStorage.getItem('refresh_token');
      const refresh = storageToken
        ? JSON.parse(localStorage.getItem('refresh_token') || '')
        : '';

      if (!refresh) {
        onError?.(error);
        return reject(error);
      }

      return axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/user/refresh/${refresh}`,
          undefined,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          onRefreshToken(res.data);
          return resolve(res);
        })
        .catch((err) => {
          onError?.(err);
          return reject(error);
        });
    } catch (err) {
      onError?.(err);
      return reject(err);
    }
  });

api.interceptors.request.use((config) => {
  try {
    const token = JSON.parse(localStorage.getItem('token') || '');
    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch {
    //...
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401 && !error?.response?.data) {
      await refreshToken(error);
    }
    return Promise.reject(error);
  }
);

export default api;
