import React from 'react';
import { Close } from '@material-ui/icons';
import { CloseButtonStyle } from './styles';

interface CloseButtonProps {
  onClick?: () => void;
  id?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({ id, onClick }) => {
  return (
    <CloseButtonStyle id={id} onClick={onClick}>
      <Close />
    </CloseButtonStyle>
  );
};

export default CloseButton;
