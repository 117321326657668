import React, { ReactElement } from 'react';
import { Modal as MuiModal, Backdrop, Fade } from '@material-ui/core';

interface ModalProps {
  children?: ReactElement;
  isOpen: boolean;
  onClose?: () => void;
  preventClose?: boolean;
  backdropColor?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  backdropColor,
  preventClose,
}) => {
  return (
    <MuiModal
      open={isOpen}
      closeAfterTransition
      onClose={() => !preventClose && onClose && onClose()}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          backgroundColor: backdropColor || 'rgba(0, 0, 0, .68)',
          backdropFilter: 'blur(2px)',
        },
      }}
    >
      <Fade in={isOpen}>{children}</Fade>
    </MuiModal>
  );
};

export default Modal;
