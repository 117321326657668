import { ChatItem, Handling, ChatItemInputModel } from 'entities';
import { TransferInputModel } from 'inputModels';
import axios from '../axios';

export const getByProtocol = (protocol: string) =>
  new Promise<Handling>((resolve, reject) => {
    const url = `/handling/${protocol}`;
    axios
      .get(url)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const sendMsg = (
  body: string,
  handling: string,
  att?: File[],
  onUploadProgress?: (progressEvent: any) => void
) => {
  const controller = new AbortController();
  const promise = new Promise<ChatItem>((resolve, reject) => {
    const bodyFormData = new FormData();
    bodyFormData.append('body', body);
    bodyFormData.append('handling', handling);
    bodyFormData.append('type', '2');

    if (att) {
      att.forEach((file: File) => {
        bodyFormData.append('attachments', file);
      });
    }

    axios
      .post('/handling/chat', bodyFormData, {
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total ?? 100;
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / total
          );
          if (onUploadProgress) onUploadProgress(percentCompleted);
        },
        signal: controller.signal,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

  return [promise, () => controller.abort.bind(controller)] as [
    Promise<ChatItem>,
    () => void
  ];
};

export const addChatItem = (
  handlingId: string,
  item: ChatItemInputModel,
  onUploadProgress?: (progressEvent: any) => void
) => {
  const controller = new AbortController();
  const promise = new Promise<ChatItem>((resolve, reject) => {
    const bodyFormData = new FormData();
    bodyFormData.append('handling', handlingId);
    bodyFormData.append('type', item.type.toString());
    bodyFormData.append('body', item.body);
    if (item.subject) bodyFormData.append('subject', item.subject);
    if (item.createdBy) bodyFormData.append('createdBy', item.createdBy);

    if (item.attachments) {
      item.attachments.forEach((file: File) => {
        bodyFormData.append('attachments', file);
      });
    }
    axios
      .post('/handling/chat', bodyFormData, {
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total ?? 100;
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / total
          );
          if (onUploadProgress) onUploadProgress(percentCompleted);
        },
        signal: controller.signal,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

  return [promise, () => controller.abort.bind(controller)] as [
    Promise<ChatItem>,
    () => void
  ];
};

export const getChatItemById = (id: string) =>
  new Promise<string>((resolve, reject) => {
    axios
      .get(`/handling/chat/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const updatePriority = (protocol: string, priorityId: string) =>
  new Promise<void>((resolve, reject) => {
    axios
      .put(`/handling/${protocol}`, { priorityId })
      .then(() => resolve())
      .catch(reject);
  });

export const makeTransfer = (transfer: TransferInputModel) =>
  new Promise<Handling>((resolve, reject) => {
    axios
      .put(`/handling/transfer`, transfer)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const undoTransfer = (handling?: string) =>
  new Promise<Handling>((resolve, reject) => {
    if (!handling) reject(new Error('No handling id provided'));
    axios
      .put(`/handling/transfer/undo/${handling}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
