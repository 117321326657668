import { ActionLogReportInputModel, GetListInputModel } from 'inputModels';
import { ActionLogViewModel } from 'viewModels';
import axios from '../axios';

export const get = ({ take = 0, page = 0, search, order }: GetListInputModel) =>
  new Promise<ActionLogViewModel[]>((resolve, reject) =>
    axios
      .get('/actionlog', {
        params: {
          search,
          order,
          page,
          take,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject)
  );

export const reportDownload = (options: ActionLogReportInputModel) =>
  new Promise<any>((resolve, reject) => {
    axios
      .post('/actionlog/report', options, {
        responseType: 'blob',
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
