import React from 'react';
import Button from '../../../components/Button';
import { Container } from './styles';

const MultiTab: React.FC = () => {
  return (
    <Container>
      <div>
        <h2>O LGPD está aberto em outra janela.</h2>
        <p>Clique em &quot;Usar aqui&quot; para usar o sistema nesta janela.</p>
        <div id="actions">
          <Button
            shape="outline"
            color="error"
            onClick={() => {
              window.history.go(-window.history.length);
              window.location.replace('https://www.legalcontrol.com.br/');
            }}
          >
            Fechar
          </Button>
          <Button onClick={() => window.location.reload()}>Usar aqui</Button>
        </div>
      </div>
    </Container>
  );
};

export default MultiTab;
