import { refreshToken, setOnError, setOnRefreshToken } from './axios';
import * as Log from './log';
import * as Auth from './auth';
import * as Role from './role';
import * as User from './user';
import * as Configs from './configs';
import * as Download from './download';
import * as Handling from './handling';
import * as Profile from './profile';
import * as LinkType from './linkType';
import * as ActionLog from './actionLog';
import * as Notification from './notification';
import * as Committee from './committee';
import * as Solicitation from './solicitation';
import * as ClassificationType from './classificationType';
import * as ConclusionStatus from './conclusionStatus';
import * as Personalization from './personalization';
import * as Upload from './upload';

const Defaults = {
  setOnError,
  refreshToken,
  setOnRefreshToken,
};

export default {
  Log,
  Auth,
  Role,
  User,
  Configs,
  Profile,
  Defaults,
  Download,
  Handling,
  LinkType,
  ActionLog,
  Committee,
  Notification,
  Solicitation,
  ClassificationType,
  ConclusionStatus,
  Personalization,
  Upload,
};
