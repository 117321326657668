import { Attach } from 'entities';

const MAX_FILE_SIZE_MB = 200;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

const handleAttachmentsChange = (e: any, att: any[]) => {
  const res = [...att, ...(e?.target?.files || [])];
  const totalSize = res.reduce((acc, file) => acc + file.size, 0);
  if (totalSize <= MAX_FILE_SIZE) {
    return res;
  }
  throw new Error(((totalSize - MAX_FILE_SIZE) / 1024 / 1024).toString());
};

const handleOpenAttachedLocalFile = (file: any) =>
  window.open(URL.createObjectURL(file));

const handleRemoveAttachedFile = (file: File, att: any[], index: number) =>
  att.filter((f, i) => i !== index || f.name !== file.name);

// const handleOpenAttachedFile = (
//   attachment: any,
//   service: (id: string, setter?: (progress: number) => void) => Promise<any>,
//   setter?: (progress: number) => void
// ) => {
//   service(attachment.id, setter).then((res) => {
//     const type = attachment.contentType;
//     const blob = new Blob([res as any], {
//       type:
//         type === 'pdf'
//           ? 'application/pdf'
//           : type === 'png'
//           ? 'image/png'
//           : type === 'jpg'
//           ? 'image/jpeg'
//           : type === 'txt'
//           ? 'text/plain'
//           : 'application/octet-stream',
//     });
//     const url = window.URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     /*link.setAttribute(
//       'download',
//       `${attachment.name}.${attachment.contentType}`
//     );*/
//     link.target = '_blank';
//     link.rel = 'noopener noreferrer';
//     link.referrerPolicy = 'no-referrer';
//     link.click();
//   });
// };

const handleOpenAttachedFile = (att: Blob, type: string, attach?: Attach) => {
  const blob = new Blob([att], {
    type,
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${attach?.originalName}.${attach?.extension}`);
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.referrerPolicy = 'no-referrer';
  link.click();
};

export {
  MAX_FILE_SIZE_MB,
  MAX_FILE_SIZE,
  handleOpenAttachedFile,
  handleAttachmentsChange,
  handleRemoveAttachedFile,
  handleOpenAttachedLocalFile,
};
