import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes as ReactRoutes } from 'react-router-dom';
import {
  Dashboard as DashboardIcon,
  FormatListBulleted,
} from '@material-ui/icons';
import { IoIosGitPullRequest } from 'react-icons/io';
import { IoPersonOutline } from 'react-icons/io5';
import { Route as RouteType } from 'routes';
import Loading from '../components/Loading';
import { useApp, useAuth } from '../context';
import AuthRoute from './AuthRoute';

import { ReactComponent as AdministrativeIcon } from '../assets/svg/administrative-icon.svg';
import { ReactComponent as ConsentIcon } from '../assets/svg/consent-icon.svg';
import { ReactComponent as GedIcon } from '../assets/svg/ged-icon.svg';
import { ReactComponent as CommitteeIcon } from '../assets/svg/icon-committee.svg';
import { ReactComponent as LogsIcon } from '../assets/svg/icon-logs.svg';
import { ReactComponent as MappingIcon } from '../assets/svg/mapping-icon.svg';
import { ReactComponent as ProfilesIcon } from '../assets/svg/profiles-icon.svg';
import { ReactComponent as ReportIcon } from '../assets/svg/report-icon.svg';
import { ReactComponent as SolicitationIcon } from '../assets/svg/solicitation-icon.svg';
import { ReactComponent as SolicitationReportIcon } from '../assets/svg/solicitation-report-icon.svg';
import { ReactComponent as TrainingIcon } from '../assets/svg/training-icon.svg';

import { ReactComponent as SolicitationIcon0 } from '../assets/svg/icon-solicitation-0.svg';
import { ReactComponent as SolicitationIcon1 } from '../assets/svg/icon-solicitation-1.svg';
import { ReactComponent as SolicitationIcon2 } from '../assets/svg/icon-solicitation-2.svg';
import MultiTabError from '../pages/Error/MultiTab';

const Home = lazy(() => import('../pages/Home'));
const Login = lazy(() => import('../pages/Login'));
const Error = lazy(() => import('../pages/Error/404'));
const Profile = lazy(() => import('../pages/Profile'));
const Tracking = lazy(() => import('../pages/Tracking'));
const Settings = lazy(() => import('../pages/Settings'));
const Dashboard = lazy(() => import('../pages/Dashboard'));

const Ged = lazy(() => import('./ged.routes'));
const Consent = lazy(() => import('./consent.routes'));
const Mapping = lazy(() => import('./mapping.routes'));
const Reports = lazy(() => import('./reports.routes'));
const Training = lazy(() => import('./training.routes'));
const Solicitation = lazy(() => import('./solicitation.routes'));
const Administrative = lazy(() => import('./administrative.routes'));

const TestPage = lazy(() => import('../tests/TestPage'));

export const Routes: RouteType[] = [
  {
    //enabled: false,
    path: 'mapping',
    title: 'Mapeamento',
    icon: <MappingIcon />,
    element: <Mapping />,
    routes: [
      {
        path: 'register',
        title: 'Registro',
        routes: [
          {
            path: 'controller',
            title: 'Controlador',
          },
          {
            path: 'costumer',
            title: 'Clientes',
          },
          {
            path: 'users',
            title: 'Usuários',
          },
          {
            path: 'third',
            title: 'Terceiros',
          },
          {
            path: 'business',
            title: 'Modalidade Empresarial',
          },
          {
            path: 'consulting',
            title: 'Modalidade Consultoria',
          },
          {
            path: 'departments',
            title: 'Departamentos',
          },
          {
            path: 'process',
            title: 'Processos',
          },
          {
            path: 'storage',
            title: 'Armazenagem',
          },
          {
            path: 'legal',
            title: 'Base Legal',
          },
          {
            path: 'finality',
            title: 'Finalidade',
          },
        ],
      },
      {
        path: 'quest',
        title: 'Questionários',
      },
      {
        path: 'import',
        title: 'Importar dados',
      },
      {
        path: 'schedule',
        title: 'Cronograma',
      },
      {
        path: 'survey',
        title: 'Levantamento de Dados',
      },
      {
        path: 'classify',
        title: 'Classificação de Dados',
      },
      {
        path: 'risk',
        title: 'Mapa de Risco',
      },
    ],
  },
  {
    enabled: false,
    path: 'consent',
    title: 'Consentimento',
    icon: <ConsentIcon />,
    element: <Consent />,
  },
  {
    path: 'solicitation',
    title: 'Solicitações',
    element: <Solicitation />,
    icon: <SolicitationIcon id="stroke" />,
    routes: [
      {
        path: 'pending',
        title: 'Pendentes',
        icon: <SolicitationIcon0 />,
        roles: ['menu-pending'],
      },
      {
        path: 'processing',
        title: 'Análise',
        roles: ['menu-analysis'],
        icon: <SolicitationIcon1 />,
      },
      {
        path: 'finished',
        title: 'Concluídas',
        roles: ['menu-closed'],
        icon: <SolicitationIcon2 />,
      },
      {
        path: 'dashboard',
        title: 'Dashboard',
        icon: <DashboardIcon />,
      },
      {
        path: 'list',
        title: 'Listagem',
        roles: ['report-list'],
        icon: <FormatListBulleted />,
      },
      {
        path: 'reports',
        title: 'Relatórios',
        roles: ['report-reports'],
        icon: <SolicitationReportIcon />,
      },
    ],
  },
  {
    path: 'ged',
    title: 'GED',
    enabled: false,
    icon: <GedIcon />,
    element: <Ged />,
  },
  {
    enabled: false,
    path: 'reports',
    title: 'Relatórios',
    roles: ['report-dashboard', 'report-list', 'menu-reports'],
    icon: <ReportIcon />,
    element: <Reports />,
  },
  {
    enabled: false,
    path: 'training',
    title: 'Treinamento',
    icon: <TrainingIcon />,
    element: <Training />,
  },
  {
    path: 'administrative',
    title: 'Administrativo',
    element: <Administrative />,
    icon: <AdministrativeIcon />,
    routes: [
      {
        path: 'users',
        title: 'Usuários',
        icon: <IoPersonOutline />,
        roles: ['administrative-users'],
      },
      {
        path: 'types',
        title: 'Tipos de Solicitação',
        icon: <IoIosGitPullRequest />,
        roles: ['administrative-classifications'],
      },
      {
        path: 'committee',
        title: 'Comitê',
        icon: <CommitteeIcon />,
        roles: ['administrative-committee'],
      },
      {
        path: 'profiles',
        title: 'Perfis',
        icon: <ProfilesIcon />,
        roles: ['administrative-profiles'],
      },
      {
        path: 'logs',
        title: 'Logs',
        icon: <LogsIcon />,
        roles: ['administrative-access-logs'],
      },
      {
        path: 'forms',
        enabled: false,
        title: 'Formulários',
        roles: ['administrative-forms'],
      },
      {
        enabled: false,
        path: 'settings',
        title: 'Configurações',
        roles: ['administrative-settings'],
      },
    ],
  },
];

const Router = () => {
  const { role } = useAuth();
  const { getConfig } = useApp();
  const activeModules = getConfig<string[]>('SYS:ACTIVE_MODULES');

  const [anotherTab, setAnotherTab] = useState(false);

  // useEffect(() => {
  //   const channel = new BroadcastChannel('tab');
  //   channel.postMessage('another-tab');
  //   channel.addEventListener('message', (msg) => {
  //     if (msg.data === 'another-tab') {
  //       setAnotherTab(true);
  //     }
  //   });
  // }, []);

  return (
    <>
      {anotherTab ? (
        <MultiTabError />
      ) : (
        <BrowserRouter>
          <Suspense fallback={<Loading shown />}>
            <ReactRoutes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              {!!role && role === 'anonymous' && (
                <Route path="/tracking" element={<Tracking />} />
              )}
              {!!role && role !== 'anonymous' && (
                <>
                  {Routes.filter(
                    (r) =>
                      r.enabled !== false && activeModules?.includes(r.path),
                  ).map((route) => (
                    <Route
                      key={route.path}
                      path={`${route.path}/*`}
                      element={
                        <AuthRoute
                          profiles={route.profiles}
                          roles={route.roles}
                        >
                          {route.element}
                        </AuthRoute>
                      }
                    />
                  ))}
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile />} />
                  {role === 'adm' && (
                    <Route path="/settings" element={<Settings />} />
                  )}
                </>
              )}
              {(!process.env.NODE_ENV ||
                process.env.NODE_ENV === 'development') && (
                <Route path="/test" element={<TestPage />} />
              )}
              <Route path="*" element={<Error />} />
            </ReactRoutes>
          </Suspense>
        </BrowserRouter>
      )}
    </>
  );
};
export default Router;
