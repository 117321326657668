import React, { forwardRef } from 'react';
import { TextField, BaseTextFieldProps } from '@material-ui/core';
import InputMask, { ReactInputMask } from 'react-input-mask';
import { useTheme } from 'styled-components';

interface InputProps extends BaseTextFieldProps {
  mask?: string;
  value: string;
  InputProps?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = forwardRef<HTMLInputElement | ReactInputMask, InputProps>(
  (props, ref) => {
    const theme = useTheme();
    const { id, mask, value, onChange, ...rest } = props;
    return (
      <div>
        {!mask ? (
          <TextField
            id={id}
            ref={ref as React.RefObject<HTMLInputElement>}
            value={value}
            onChange={onChange}
            style={{ color: theme.colors.primary }}
            {...rest}
          />
        ) : (
          <InputMask
            ref={ref as React.RefObject<ReactInputMask>}
            id={id}
            mask={mask}
            value={value}
            onChange={onChange}
          >
            <TextField style={{ color: theme.colors.primary }} {...rest} />
          </InputMask>
        )}
      </div>
    );
  },
);

export default Input;
