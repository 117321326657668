import React, { lazy } from 'react';
import { useAuth } from '../../context';

const Error = lazy(() => import('../../pages/Error/404'));

interface RouteProps {
  children?: React.ReactNode;
  roles?: string[];
  profiles?: string[];
}

const Route: React.FC<RouteProps> = ({
  profiles = [],
  roles = [],
  children,
}) => {
  const auth = useAuth();

  return (profiles.length === 0 && roles.length === 0) ||
    ['adm', 'dpo'].includes(auth.role) ||
    profiles.some((profile) => auth.role.includes(profile)) ||
    roles.some((r) => auth.roles.includes(r)) ? (
    <>{children}</>
  ) : (
    <Error
      statusCode={401}
      message="Parece que você não tem permissão para acessar esta página."
    />
  );
};

export default Route;
