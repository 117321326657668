import axios from '../axios';

export const getById = (
  id: string,
  setProgress?: (progress: number) => void
) => {
  const controller = new AbortController();

  const promise = new Promise<Blob>((resolve, reject) => {
    axios
      .get(`/download/${id}`, {
        onDownloadProgress: (progressEvent) => {
          const total = progressEvent.total ?? 100;
          const progress = Math.round(
            (progressEvent.loaded * 100) / total
          );
          setProgress?.(progress);
        },
        signal: controller.signal,
        responseType: 'blob',
      })
      .then((response) => resolve(response.data))
      .catch(reject);
  });

  return [promise, () => controller.abort.bind(controller)] as [
    Promise<Blob>,
    () => void
  ];
};
