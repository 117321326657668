import { Committee, CommitteeInputModel } from 'entities';
import axios from '../axios';

export const create = (committee: CommitteeInputModel) =>
  new Promise<{ id: string }>((resolve, reject) => {
    axios
      .post('/committee', committee)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const duplicate = (id: string) =>
  new Promise<{ id: string }>((resolve, reject) => {
    axios
      .post(`/committee/duplicate/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const update = (committee: CommitteeInputModel) =>
  new Promise((resolve, reject) => {
    axios
      .put('/committee', committee)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const get = (search?: string, page: number = 1) =>
  new Promise<Committee[]>((resolve, reject) => {
    axios
      .get('/committee', {
        params: {
          search,
          page,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getByUserId = (userId?: string) =>
  new Promise<Committee[]>((resolve, reject) => {
    axios
      .get(`/committee/user/${userId}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getItems = (search?: string) =>
  new Promise<{ id: string; value: string }[]>((resolve, reject) => {
    axios
      .get('/committee/items', {
        params: {
          search,
        },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getById = (id: string) =>
  new Promise<Committee>((resolve, reject) => {
    axios
      .get(`/committee/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const disable = (id: string) =>
  new Promise<void>((resolve, reject) => {
    axios
      .put(`/committee/disable/${id}`)
      .then(() => resolve())
      .catch(reject);
  });
