import React from 'react';
import { PermScanWifi } from '@material-ui/icons';
import Button from '../Button';
import Modal from '../Modal';
import { Container } from './styles';

interface NetworkErrorProps {
  shown?: boolean;
}

const NetworkError: React.FC<NetworkErrorProps> = ({ shown = false }) => {
  return (
    <Modal isOpen={shown} backdropColor="rgba(0, 0, 0, 0.29)">
      <Container>
        <div className="network-error">
          <PermScanWifi />
          <h2>Oops!</h2>
          <p id="info-1">
            Parece que você está offline. Por favor, verifique sua internet
            conexão e tente novamente.
          </p>

          <p id="info-2">
            Se você tiver certeza de que está online, entre em contato com o
            administrador deste site.
          </p>

          <a href="mailto:contato@legalcontrol.com.br">
            Falar com o administrador
          </a>
          <Button
            shape="outline"
            size="small"
            color="primary"
            onClick={() => window.location.reload()}
          >
            Recarregar
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default NetworkError;
