import { PriorityInputModel, ClassificationTypeInputModel } from 'inputModels';
import { PriorityViewModel, ClassificationTypeViewModel } from 'viewModels';
import axios from '../axios';

export const getEnabled = () =>
  new Promise<ClassificationTypeViewModel[]>((resolve, reject) => {
    axios
      .get('/classificationtype')
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const getById = (id: string) =>
  new Promise<ClassificationTypeViewModel>((resolve, reject) => {
    axios
      .get(`/classificationtype/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const create = (classification: ClassificationTypeInputModel) =>
  new Promise<{ id: string }>((resolve, reject) => {
    axios
      .post('/classificationtype', classification)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const update = (classification: ClassificationTypeInputModel) =>
  new Promise<void>((resolve, reject) => {
    axios
      .put('/classificationtype', classification)
      .then(() => resolve())
      .catch(reject);
  });

export const disable = (id: string) =>
  new Promise<void>((resolve, reject) => {
    axios
      .put(`/classificationtype/disable/${id}`)
      .then(() => resolve())
      .catch((error) => {
        reject(error);
      });
  });

export const getPriorities = () =>
  new Promise<PriorityViewModel[]>((resolve, reject) => {
    axios
      .get(`/classificationtype/priority`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const setPriorities = (priorities: PriorityInputModel[]) =>
  new Promise<void>((resolve, reject) => {
    axios
      .put(`/classificationtype/priority`, priorities)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
