
const reportWebVitals = (onPerfEntry?: (metric: any) => void ) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    import('web-vitals').then(({ onCLS,onFCP, onLCP, onTTFB }) => {
      onCLS(onPerfEntry);
      onFCP(onPerfEntry);
      onLCP(onPerfEntry);
      onTTFB(onPerfEntry);
    }).catch((err) => {
      console.error('Erro ao carregar o web-vitals:', err);
    });
  }
};

export default reportWebVitals;