import axios from '../axios';

export const upload = (item: any) => {
  const formData = new FormData();
  formData.append('File', item.File);
  formData.append('FileName', item.FileName);
  formData.append('SaveRef', item.SaveRef);

  return new Promise<any>((resolve, reject) => {
    axios
      .post('upload/upload-file-by-name', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadFileByName = (nameFile: string, extension: string) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .post(`download/by-file-name`, {
        nameFile,
        extension,
      })
      .then((data) => resolve(data))
      .catch(reject);
  });
};
