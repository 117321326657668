import useReactWebSocket from 'react-use-websocket';

interface Props<T> {
  url: string;
  params?: { [key: string]: string };
  onMessage?: (message: T) => void;
  onOpen?: (event: Event) => void;
  onError?: (event: Event) => void;
  token?: string;
}

const useWebSocket = <T>({
  url,
  params,
  onMessage,
  onOpen,
  onError,
}: Props<T>) => {
  const { lastMessage, sendMessage } = useReactWebSocket(
    url.includes('undefined')
      ? null
      : `wss://${process.env.REACT_APP_API_BASE_URL?.split('//')[1].replace(
          '/api',
          ''
        )}/${url}`,
    {
      onOpen,
      onError,
      onMessage: ({ data }) => onMessage?.(JSON.parse(data) as T),
      queryParams: { ...(params || {}) },
      shouldReconnect: () => true,
      reconnectInterval: 3000,
    }
  );

  return { lastMessage, sendMessage };
};

export default useWebSocket;
