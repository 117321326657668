import React, { createContext, useEffect, useState, useContext } from 'react';
import { PriorityViewModel } from 'viewModels';
import { Props } from 'props';
import { useAuth } from '../auth';
import Api from '../../api';

type FormType = {
  name: string;
  cpf: string;
  phone: string;
  email: string;
  description: string;
  classificationType: string;
  linkType: string;
  status: string;
  date: string;
  attachments: File[];
  protocol: string;
};

type SolicitationContextProps = {
  currentForm: number;
  handleCurrentForm: (increment: number) => void;
  solicitationForm: FormType;
  setSolicitationForm: (props: any) => void;
  priorities: PriorityViewModel[];
  setPriorities: (props: PriorityViewModel[]) => void;
};
const SolicitationContext = createContext<SolicitationContextProps>(
  {} as SolicitationContextProps
);

const Solicitation: React.FC<Props> = ({ children }) => {
  const { tokenIsValid } = useAuth();
  const [currentForm, setCurrentForm] = useState(0);
  const [priorities, setPriorities] = useState<PriorityViewModel[]>([]);
  const [solicitationForm, setFormProp] = useState({} as FormType);
  const handleCurrentForm = (increment: number) =>
    setCurrentForm((prev) => prev + increment);
  
  const setSolicitationForm = (props: any) =>
    setFormProp((prev) => (props === undefined ? {} : { ...prev, ...props }));

  useEffect(() => {
    if (tokenIsValid)
      Api.ClassificationType.getPriorities().then(setPriorities);
  }, [tokenIsValid]);

  return (
    <SolicitationContext.Provider
      value={{
        currentForm,
        handleCurrentForm,
        setSolicitationForm,
        solicitationForm,
        priorities,
        setPriorities,
      }}
    >
      {children}
    </SolicitationContext.Provider>
  );
};

export const useSolicitation: () => SolicitationContextProps = () =>
  useContext(SolicitationContext);

export default Solicitation;
